import React from 'react'
import {Banner, CardBackground, CardSingleItem, Container, Icon, Modal, Navbar, PageSection} from './../components/'
import { Link } from 'react-router-dom'
import { toursData, fleetData, transfersData } from '../@utils/data'
import { Trans, useTranslation } from 'react-i18next'

const Home = () => {
  const { t, i18n } = useTranslation()

  let langTours = undefined
  let langTransfers = undefined

  switch (i18n.language) {
    case 'en':
      langTours = toursData.en
      langTransfers = transfersData.en
      break
    case 'pt-PT':
      langTours = toursData.pt
      langTransfers = transfersData.pt
      break
  }

  return (
    <>
      <Banner as="header" className="bg-gradient-to-bl from-orange to-light-orange">
        <div className="flex flex-col gap-y-6 items-center">
          <Container as="section" className="text-center text-white">
            <h1 className="text-h1 mx-auto w-6/12 mb-4">{ t('home.bannerTitle') }</h1>
            <p className="text-white mx-auto w-8/12 mb-5">{ t('home.bannerDescription') }</p>
          </Container>
          <Container as="section" className="flex gap-x-6 justify-items-center">
            <article className="w-full sm:w-7/12 lg:w-5/12">
              <CardBackground className="h-52">
                <CardBackground.Image>
                  <img src={require('./../assets/img/guincho.png')} alt="On demand" />
                </CardBackground.Image>
                <CardBackground.Eyebrow>{ t('home.onDemandTour') }</CardBackground.Eyebrow>
                <CardBackground.Heading>{ t('home.dreamComeTrue') }</CardBackground.Heading>
                <CardBackground.Action>
                  <Link to="/contacts" className="btn btn-link">{ t('buttons.createYourDreamTour') }</Link>
                </CardBackground.Action>
              </CardBackground>
            </article>
            <article className="hidden sm:block sm:w-5/12 lg:w-4/12">
              <CardBackground className="h-52">
                <CardBackground.Image>
                  <img src={require('./../assets/img/cascais.webp')} alt="On demand" />
                </CardBackground.Image>
              </CardBackground>
            </article>
            <article className="hidden lg:block lg:w-3/12">
              <CardBackground className="h-52">
                <CardBackground.Image>
                  <img src={require('./../assets/img/estoril.webp')} alt="On demand" />
                </CardBackground.Image>
              </CardBackground>
            </article>
          </Container>
        </div>
      </Banner>

      <PageSection>
        <PageSection.Eyebrow>
          { t('aboutUs.eyebrow') }
        </PageSection.Eyebrow>
        <PageSection.Heading>
          { t('aboutUs.title') }
        </PageSection.Heading>
        <PageSection.Description>
          <Trans i18nKey="aboutUs.description">
          </Trans>
          <div className="flex flex-col items-center justify-center mt-4">
            <Link className="w-full sm:w-6/12 lg:w-5/12 flex flex-col items-center justify-center gap-x-2" to='tel:+351917777107'>
              <img className='h-36 rounded-full' src={require(`./../assets/img/fotoManager.jpeg`)} alt="Nuno - CEO" />
              <div className="flex flex-col items-center mt-2">
                <h4 className='text-h5 text-black leading-6'>Nuno Baptista</h4>
                <p className='font-semibold'>CEO & Manager</p>
              </div>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center mt-4">
            <Link className="w-10/12 sm:w-6/12 lg:w-5/12 flex flex-col sm:flex-row items-center justify-center gap-x-2" to='tel:+351917777107'>
              <Icon iconName='phone_call_line' className='text-h1' iconColor='text-orange'></Icon>
              <div className="flex flex-col items-center sm:items-start">
                <h4 className='text-h5 text-black leading-6'>Phone:</h4>
                <p className='font-semibold'>+351 917 777 107</p>
              </div>
            </Link>
            <Link className="w-10/12 sm:w-6/12 lg:w-5/12 flex flex-col sm:flex-row items-center justify-center gap-x-2" to='mailto:drive4you2023@gmail.com'>
              <Icon iconName='mailbox_line' className='text-h1' iconColor='text-orange'></Icon>
              <div className="flex flex-col items-center sm:items-start">
                <h4 className='text-h5 text-black leading-6'>Email:</h4>
                <p className="font-semibold">drive4you2023@gmail.com</p>
              </div>
            </Link>
          </div>
        </PageSection.Description>
        <PageSection.Actions>
          <Link className='btn' to="/about-us">{ t('buttons.getToKnowMore') }</Link>
        </PageSection.Actions>
      </PageSection>

      <PageSection className="bg-sky-blue">
        <PageSection.Eyebrow>
          { t('tours.eyebrow') }
        </PageSection.Eyebrow>
        <PageSection.Heading>
          { t('home.toursTitle') }
        </PageSection.Heading>
        <PageSection.Description>
          { t('tours.description') }
        </PageSection.Description>
        <PageSection.Content className='columns-1 gap-6 space-y-6 sm:columns-2 lg:columns-3'>
          {langTours.map( (tour, i) => {
            if (i <= 2) {
              return (
                <div key={tour.id} className={`w-full relative ${ i === 2 ? 'sm:hidden lg:block' : '' }`}>
                  <CardSingleItem className={`w-full`} thumbnail={tour.thumbnail} thumbnailAlt={tour.thumbnailAlt}>
                    <CardSingleItem.Header>
                      <img src={require(`./../assets/img/${tour.thumbnail}`)} alt={tour.thumbnailAlt} />
                    </CardSingleItem.Header>
                    <CardSingleItem.Body>
                      <CardSingleItem.Heading>
                        <h3>{tour.title}</h3>
                      </CardSingleItem.Heading>
                      <CardSingleItem.Description>
                        <CardSingleItem.PriceInfo estDuration={tour.duration} startingFrom={t('general.startingFrom', { cost: tour.startingFrom })} />
                      </CardSingleItem.Description>
                      <CardSingleItem.Action to={`/tours/${tour.slug}`}>
                        <span>{t('buttons.bookThisTour')}</span>
                      </CardSingleItem.Action>
                    </CardSingleItem.Body>
                  </CardSingleItem>
                </div>
              )
            }
          } )}
        </PageSection.Content>
        <PageSection.Actions>
          <Link to='/tours' className='btn btn-secondary'>{ t('buttons.findDreamTour') }</Link>
          <Link to='/contacts' className='btn'>  { t('buttons.createYourDreamTour') }</Link>
        </PageSection.Actions>
      </PageSection>

      <PageSection>
        <PageSection.Eyebrow>
          { t('home.fleetEyebrow') }
        </PageSection.Eyebrow>
        <PageSection.Heading>
          { t('home.fleetTitle') }
        </PageSection.Heading>
        <PageSection.Description>
          { t('home.fleetDescription') }
        </PageSection.Description>
        <PageSection.Content className='columns-1 gap-6 px-24 md:columns-2 xl:px-36'>
          {fleetData.map( (fleet, i) => {
            return (
              <div key={fleet.id} className={`w-full relative ${ i === 2 ? 'sm:hidden' : '' }`}>
                <CardSingleItem key={fleet.id} className="w-full">
                  <CardSingleItem.Header>
                    <img src={require(`./../assets/img/${fleet.thumbnailSrc}`)} alt={fleet.thumbnailAlt} />
                  </CardSingleItem.Header>
                  <CardSingleItem.Body>
                    <CardSingleItem.Heading className='flex gap-x-1'>
                      <h3>{fleet.makeModel} <span className="text-grey text-h5">({fleet.pax} Pax)</span></h3>
                    </CardSingleItem.Heading>
                  </CardSingleItem.Body>
                </CardSingleItem>
              </div>
            )
          } )}
        </PageSection.Content>
      </PageSection>

      <PageSection className="bg-sky-blue">
        <PageSection.Eyebrow>
          { t('home.transfersEyebrow') }
        </PageSection.Eyebrow>
        <PageSection.Heading>
          { t('home.transfersTitle') }
        </PageSection.Heading>
        <PageSection.Description>
          { t('transfers.description') }
        </PageSection.Description>
        <PageSection.Content className='columns-1 gap-6 sm:columns-2 lg:columns-3'>
          {langTransfers.map( (transfer, i) => {
            return (
              <div className={`w-full relative ${ i === 2 ? 'sm:hidden lg:block' : '' }`}>
                <CardSingleItem key={transfer.id} className={`w-full`} thumbnail={transfer.thumbnail} thumbnailAlt={transfer.thumbnailAlt}>
                  <CardSingleItem.Header>
                    <img src={require(`./../assets/img/${transfer.thumbnail}`)} alt={transfer.thumbnailAlt} />
                  </CardSingleItem.Header>
                  <CardSingleItem.Body>
                    <CardSingleItem.Heading>
                      <h3>{transfer.title}</h3>
                    </CardSingleItem.Heading>
                    <CardSingleItem.Description>
                      <CardSingleItem.PriceInfo startingFrom={transfer.startingFrom} />
                    </CardSingleItem.Description>
                    <CardSingleItem.Action to={`/transfers/${transfer.slug}`}>
                      <span>{ t('buttons.bookThisTransfer') }</span>
                    </CardSingleItem.Action>
                  </CardSingleItem.Body>
                </CardSingleItem>
              </div>
            )
          } )}
        </PageSection.Content>
        <PageSection.Actions>
          <Link to="/contacts" className='btn'>{ t('buttons.createCustomTransfer') }</Link>
        </PageSection.Actions>
      </PageSection>
    </>
  )
}

export default Home
