export const toursData = {
  en: [
    {
    slug: 'lisbon-by-night-fado',
    id: 0,
    thumbnail: 'tours/lisbon-by-night_v2.webp',
    thumbnailAlt: 'Image - Lisbon By Night (c/ Fado)',
    title: 'Lisbon By Night (c/ Fado)',
    titlePt: 'Lisboa Pela Noite (c/ Fado)',
    startingFrom: '25€',
    description: "Experience the captivating allure of Lisbon by night with Drive4You, as the city reveals its enchanting charm and embraces the soul-stirring melodies of Fado. As twilight descends, the narrow streets of Alfama come alive, adorned with flickering lights and the distant echoes of hauntingly beautiful music. Immerse yourself in the intimate atmosphere of traditional Fado houses, where the heartfelt voices of talented musicians intertwine with the emotional strumming of Portuguese guitars, creating a mesmerizing symphony that resonates deep within your soul.\n\nDrive4You offers you the opportunity to delve into the heart of Lisbon's Fado scene, ensuring an unforgettable experience filled with passion and artistry. From the renowned Fado establishments in Alfama to the hidden taverns tucked away in Mouraria, our expert drivers will take you on a curated journey through Lisbon's vibrant Fado landscape. Whether you are a seasoned Fado enthusiast or new to this captivating music genre, allow us to transport you to a world where time seems to stand still, and the beauty of Lisbon by night is perfectly harmonized with the captivating melodies of Fado.",
    costs: {
      oneToThree: '180€',
      fourToSix: '225€',
      sevenPlus: '50€ per person'
    },
    duration: '5 hours',
    keyDetails: [
      "We'll pick you up at your designated address.",
      "Departure time: 18:00"
    ],
    included: [
      "Driver/guide",
      "Hotel pickup and drop-off",
      "Private tour",
      "Bottled water",
      "Free Wi-Fi"
    ],
    notIncluded: [
      "Dinner"
    ],
    optional: [
      "Infant seats are available on request if advised at time of booking"
    ]
    },
    {
      slug: 'sintra-cascais-estoril',
      id: 1,
      thumbnail: 'tours/sintra-cascais-estoril.webp',
      thumbnailAlt: 'Image - Sintra/Lisboa/Cascais',
      title: 'Sintra/Lisboa/Cascais',
      startingFrom: '40€',
      description: "Experience the wonders of Sintra, Lisboa, and Cascais like never before with Drive4You, your ultimate companion for an unforgettable journey through these captivating Portuguese gems.\n\nEmbark on a seamless adventure curated to perfection, where every moment is tailored to your preferences. Drive4You boasts an expert team of local guides who are not only well-versed in the history, culture, and hidden treasures of these regions but also dedicated to ensuring your comfort and enjoyment throughout the trip.\n\nPicture yourself winding through Sintra's lush forests and discovering its enchanting palaces, with Drive4You's knowledgeable guides narrating tales of royalty and intrigue. Feel the pulse of Lisboa's vibrant neighborhoods as you explore its historic streets, vibrant markets, and panoramic viewpoints, all thoughtfully mapped by Drive4You to capture the heart of the city's spirit.\n\nBut the journey doesn't end there. Drive4You chauffeurs you along the stunning coastal roads to Cascais, where sun-kissed beaches and a charming maritime ambience await. With Drive4You's meticulously planned routes, you'll savor every moment of this coastal escapade.\n\nWith Drive4You, it's not just a tour; it's an immersion. Let their expertise and personalized approach paint your travel canvas with the most vibrant colors of Sintra, Lisboa, and Cascais. Your dream exploration is just a drive away.",
      costs: {
        oneToThree: '280€',
        fourToSix: '380€',
        sevenPlus: '50€ per person'
      },
      duration: '8 hours',
      keyDetails: [
        "We'll pick you up at your designated address.",
        "Departure time: 9:00am"
      ],
      included: [
        "Driver/guide",
        "Hotel pickup and drop-off",
        "Private tour",
        "Bottled water",
        "Free Wi-Fi"
      ],
      notIncluded: [
        "Dinner"
      ],
      optional: [
        "Infant seats are available on request if advised at time of booking"
      ]
    },
    {
      slug: 'fatima-mosteiro-batalha',
      id: 2,
      thumbnail: 'tours/thumb_fatima-mosteiro@2x.webp',
      thumbnailAlt: 'Image - Fátima/Mosteiro da Batalha',
      title: 'Fátima/Mosteiro da Batalha',
      startingFrom: '45€',
      description: "Discover the heart of Portugal's cultural and historical treasures with Drive4You's unparalleled tour experience in Fátima and Mosteiro da Batalha. Immerse yourself in a journey that transcends mere sightseeing as you're guided through these iconic destinations with expertise and finesse.\n\nDrive4You distinguishes itself by tailoring each tour to your preferences, ensuring an intimate and personalized exploration. Our knowledgeable local guides possess an innate passion for the region's history, culture, and significance. They seamlessly weave captivating narratives, recounting the tales of Fátima's spiritual significance and the awe-inspiring architecture of Mosteiro da Batalha.\n\nAs you traverse these landmarks, you'll enjoy the convenience and comfort of our top-tier transportation fleet, which guarantees a seamless and stress-free experience. Revel in the majesty of Mosteiro da Batalha's intricate Gothic design, a UNESCO World Heritage site that stands as a testament to Portugal's rich history.\n\nWhether you're a history enthusiast, a spiritual seeker drawn to Fátima's religious heritage, or an admirer of architectural marvels, Drive4You crafts a tailored journey that caters to all interests. Let us be your gateway to an unforgettable adventure, where every moment is designed to create memories that linger for a lifetime.",
      costs: {
        oneToThree: '300€',
        fourToSix: '380€',
        sevenPlus: '45€ per person'
      },
      duration: '7-9 hours',
      keyDetails: [
        "We'll pick you up at your designated address.",
        "Departure time: 9:00am"
      ],
      included: [
        "Driver/guide",
        "Hotel pickup and drop-off",
        "Private tour",
        "Bottled water",
        "Free Wi-Fi"
      ],
      notIncluded: [
        "Dinner"
      ],
      optional: [
        "Infant seats are available on request if advised at time of booking"
      ]
    },
    {
      slug: 'tasteful-porto',
      id: 3,
      thumbnail: 'tours/thumb_porto@2x.webp',
      thumbnailAlt: 'Image - Porto',
      title: 'Tasteful Porto',
      startingFrom: '90€',
      description: "Discover the enchanting city of Porto in the most captivating way with Drive4You - your ultimate companion for an unforgettable tour. Immerse yourself in the beauty and culture of this Portuguese gem while our expert drivers navigate the city's hidden treasures and iconic landmarks.\n\nDrive4You redefines your touring experience through personalized attention and insider insights. Our passionate local drivers are not just guides, but storytellers, ready to unveil Porto's rich history and vibrant spirit. Whether you're strolling through the charming Ribeira district, savoring world-renowned Port wines in historic cellars, or gazing at the Douro River weaving through the city, Drive4You ensures every moment is steeped in authenticity.\n\nTailored to your preferences, our tours offer the flexibility to explore Porto at your pace. From architectural wonders like the São Bento train station to the contemporary marvels of Casa da Música, Drive4You crafts a seamless itinerary that captures the essence of Porto's past and present.\n\nLeave the maps behind and embark on a journey of discovery with Drive4You. Allow us to navigate, educate, and create memories that will linger long after your Porto adventure concludes. Unveil the soul of Porto like never before, with Drive4You as your devoted guide.",
      costs: {
        oneToThree: '500€',
        fourToSix: '650€',
        sevenPlus: '90€ per person'
      },
      duration: '12 hours',
      keyDetails: [
        "We'll pick you up at your designated address.",
        "Departure time: 6:00"
      ],
      included: [
        "Driver/guide",
        "Hotel pickup and drop-off",
        "Private tour",
        "Bottled water",
        "Free Wi-Fi"
      ],
      notIncluded: [
        "Lunch"
      ],
      optional: [
        "Skip the line: you can choose if you want to buy the tour with attractions tickets included or not.",
        "Infant seats are available on request if advised at time of booking"
      ]
    },
    {
      slug: 'nazare-obidos-azenhas',
      id: 4,
      thumbnail: 'tours/thumb_nazare-obidos-azenhas@2x.webp',
      thumbnailAlt: 'Image - Nazaré/Óbidos/Azenhas do Mar',
      title: 'Nazaré/Óbidos/Azenhas do Mar',
      startingFrom: '60€',
      description: "Experience the wonders of Portugal like never before with Drive4You, your ultimate tour companion. Embark on an unforgettable journey as we guide you through the captivating locales of Nazaré, Óbidos, and Azenhas do Mar, each brimming with history, charm, and natural beauty.\n\nStarting in Nazaré, famed for its colossal waves and rich maritime heritage, Drive4You ensures you don't miss a single highlight. From the iconic lighthouse offering panoramic coastal views to the bustling fisherman's village, every corner tells a story. Moving on to Óbidos, a medieval gem encircled by ancient walls, our expert guides lead you through cobblestone streets, revealing hidden treasures like charming cafes and traditional crafts.\n\nAs the sun sets, we venture to Azenhas do Mar, a coastal paradise where the sea meets dramatic cliffs. With Drive4You, you'll relish in the breathtaking sunset, capturing the moment forever. Our personalized approach means you set the pace, giving you ample time to explore, take photos, and soak in the local culture. With every detail meticulously planned, from comfortable transport to insider insights, Drive4You is your ticket to an immersive and unparalleled adventure through Nazaré, Óbidos, and Azenhas do Mar.",
      costs: {
        oneToThree: '350€',
        fourToSix: '400€',
        sevenPlus: '60€ per person'
      },
      duration: '8 hours',
      keyDetails: [
        "We'll pick you up at your designated address.",
        "Departure time: 8:30"
      ],
      included: [
        "Driver/guide",
        "Hotel pickup and drop-off",
        "Private tour",
        "Bottled water",
        "Free Wi-Fi"
      ],
      notIncluded: [],
      optional: [
        "Infant seats are available on request if advised at time of booking"
      ]
    },
    {
      slug: 'sesimbra-arrábida',
      id: 5,
      thumbnail: 'tours/thumb_sesimbra-arrabida@2x.webp',
      thumbnailAlt: 'Image - Sesimbra/Arrábida',
      title: 'Sesimbra/Arrábida',
      startingFrom: '50€',
      description: "Discover the unparalleled beauty of Sesimbra and Arrábida with Drive4You, your ultimate companion for an exceptional tour experience. Immerse yourself in the breathtaking landscapes and rich cultural heritage of these captivating destinations, all while enjoying the comfort and convenience of a personalized tour.\n\nDrive4You stands out by offering a seamless blend of expertise and hospitality. Our seasoned local guides possess an in-depth understanding of the region's history, culture, and hidden gems. With their guidance, you'll explore the charming streets of Sesimbra, adorned with traditional Portuguese architecture, and be captivated by the azure waters of its pristine beaches.\n\nThe journey continues to Arrábida, where rugged cliffs meet the crystal-clear waters of the Atlantic Ocean. Drive4You ensures that you won't miss a single panoramic view or serene enclave, taking you to the most awe-inspiring vantage points that overlook this natural wonder.\n\nWhat sets Drive4You apart is their commitment to crafting a tour that suits your preferences. Whether you're an adventure enthusiast, a history buff, or a photography aficionado, each tour is tailored to meet your desires. Revel in the freedom to savor every moment, knowing that Drive4You has designed an itinerary that resonates with your passions.\n\nChoose Drive4You for an unforgettable journey through Sesimbra and Arrábida, where every twist of the road brings new discoveries and where every moment is marked by comfort, knowledge, and sheer wonder.",
      costs: {
        oneToThree: '280€',
        fourToSix: '380€',
        sevenPlus: '50€ per person'
      },
      duration: '8 hours',
      keyDetails: [
        "We'll pick you up at your designated address.",
        "Departure time: 9:00"
      ],
      included: [
        "Driver/guide",
        "Hotel pickup and drop-off",
        "Private tour",
        "Bottled water",
        "Free Wi-Fi"
      ],
      notIncluded: [],
      optional: [
        "Infant seats are available on request if advised at time of booking"
      ]
    }
  ],
  pt: [
    {
      slug: 'lisbon-by-night-fado',
      id: 0,
      thumbnail: 'tours/lisbon-by-night_v2.webp',
      thumbnailAlt: 'Imagem - Lisboa à noite (c/ Fado)',
      title: 'Lisboa à noite (c/ Fado)',
      startingFrom: '25€',
      description: "A Drive4You oferece-lhe uma experiência nocturna de Lisboa, onde a cidade revela o seu encanto e abraça as melodias do Fado. À medida que o crepúsculo desce, as ruas estreitas de Alfama ganham vida, adornadas com luzes cintilantes e os ecos distantes de uma música assombrosamente bela. Mergulhe na atmosfera íntima das casas de Fado tradicionais, onde as vozes sentidas de músicos talentosos se entrelaçam com o dedilhar emocional das guitarras portuguesas, criando uma sinfonia hipnotizante que ressoa no fundo da sua alma.\n\nDrive4You oferece-lhe a oportunidade de mergulhar no coração da cena do Fado de Lisboa, garantindo uma experiência inesquecível cheia de paixão e arte. Desde os famosos estabelecimentos de Fado em Alfama até às tabernas escondidas na Mouraria, os nossos condutores experientes levá-lo-ão numa viagem com curadoria através da vibrante paisagem do Fado em Lisboa. Quer seja um entusiasta experiente do Fado ou um novato neste cativante género musical, permita-nos transportá-lo para um mundo onde o tempo parece parar, e a beleza de Lisboa à noite é perfeitamente harmonizada com as cativantes melodias do Fado.",
      costs: {
        oneToThree: '180€',
        fourToSix: '225€',
        sevenPlus: '50€ per person'
      },
      duration: '5 horas',
      keyDetails: [
        "Vamos buscá-lo à morada que nos indicar",
        "Hora de partida: 18:00"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [
        "Jantar"
      ],
      optional: [
        "Os assentos para bebés estão disponíveis mediante pedido, se avisados no momento da reserva"
      ]
    },
    {
      slug: 'sintra-cascais-estoril',
      id: 1,
      thumbnail: 'tours/sintra-cascais-estoril.webp',
      thumbnailAlt: 'Imagem - Sintra/Lisboa/Cascais',
      title: 'Sintra/Lisboa/Cascais',
      startingFrom: '40€',
      description: "Experimente as maravilhas de Sintra, Lisboa e Cascais como nunca antes com a Drive4You, o seu melhor companheiro para uma viagem inesquecível através destas cativantes jóias portuguesas.\n\nEmbarque numa aventura perfeita, onde cada momento é adaptado às suas preferências. A Drive4You possui uma equipa especializada de guias locais que não só conhecem bem a história, a cultura e os tesouros escondidos destas regiões, como também se dedicam a garantir o seu conforto e prazer durante toda a viagem.\n\nImagine-se a serpentear pelas florestas luxuriantes de Sintra e a descobrir os seus palácios encantadores, com os guias experientes da Drive4You a narrar histórias de realeza e intriga. Sinta o pulsar dos vibrantes bairros de Lisboa enquanto explora as suas ruas históricas, mercados vibrantes e miradouros panorâmicos, todos cuidadosamente mapeados pela Drive4You para captar o coração do espírito da cidade.\n\nMas a viagem não acaba aqui. A Drive4You leva-o ao longo das deslumbrantes estradas costeiras até Cascais, onde o esperam praias ensolaradas e um encantador ambiente marítimo. Com os percursos meticulosamente planeados pela Drive4You, irá saborear cada momento desta escapadela costeira.\n\nCom a Drive4You, não se trata apenas de um passeio, é uma imersão. Deixe que a sua experiência e abordagem personalizada pintem a sua tela de viagem com as cores mais vibrantes de Sintra, Lisboa e Cascais. A sua exploração de sonho está apenas a uma viagem de carro.",
      costs: {
        oneToThree: '280€',
        fourToSix: '385€',
        sevenPlus: '50€ per person'
      },
      duration: '8 horas',
      keyDetails: [
        "Vamos buscá-lo à sua morada designada.",
        "Hora de partida: 9:00am"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [
        "Jantar"
      ],
      optional: [
        "Os assentos para bebés estão disponíveis mediante pedido, se avisados no momento da reserva"
      ]
    },
    {
      slug: 'fatima-mosteiro-batalha',
      id: 2,
      thumbnail: 'tours/thumb_fatima-mosteiro@2x.webp',
      thumbnailAlt: 'Imagem - Fátima/Mosteiro da Batalha',
      title: 'Fátima/Mosteiro da Batalha',
      startingFrom: '45€',
      description: "Descubra o coração dos tesouros culturais e históricos de Portugal com a experiência inigualável da Drive4You em Fátima e no Mosteiro da Batalha. Mergulhe numa viagem que transcende a mera visita turística, sendo guiado por estes destinos emblemáticos com perícia e delicadeza.\n\nA Drive4You distingue-se por adaptar cada tour às suas preferências, assegurando uma exploração íntima e personalizada. Os nossos experientes guias locais possuem uma paixão inata pela história, cultura e significado da região. Eles tecem narrativas cativantes, contando as histórias do significado espiritual de Fátima e a arquitetura inspiradora do Mosteiro da Batalha.\n\nAo percorrer estes pontos de referência, desfrutará da comodidade e conforto da nossa frota de transportes de topo, que garante uma experiência perfeita e sem stress. Aprecie a majestade do intrincado design gótico do Mosteiro da Batalha, Património Mundial da UNESCO, que é um testemunho da rica história de Portugal.\n\nQuer seja um entusiasta da história, um buscador espiritual atraído pela herança religiosa de Fátima, ou um admirador de maravilhas arquitectónicas, a Drive4You cria uma viagem à medida de todos os interesses. Deixe-nos ser a sua porta de entrada para uma aventura inesquecível, onde cada momento é concebido para criar memórias que perduram para toda a vida.",
      costs: {
        oneToThree: '300€',
        fourToSix: '380€',
        sevenPlus: '45€ per person'
      },
      duration: '7-9 horas',
      keyDetails: [
        "Vamos buscá-lo à sua morada designada.",
        "Hora de partida: 9:00am"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [
        "Jantar"
      ],
      optional: [
        "Os assentos para bebés estão disponíveis mediante pedido, se avisados no momento da reserva"
      ]
    },
    {
      slug: 'tasteful-porto',
      id: 3,
      thumbnail: 'tours/thumb_porto@2x.webp',
      thumbnailAlt: 'Imagem - Bom Porto',
      title: 'Porto',
      startingFrom: '90€',
      description: "Descubra a encantadora cidade do Porto da forma mais cativante com a Drive4You - a sua melhor companhia para um passeio inesquecível. Mergulhe na beleza e cultura desta joia portuguesa enquanto os nossos motoristas especializados percorrem os tesouros escondidos da cidade e os seus marcos icónicos.\n\nA Drive4You redefine a sua experiência turística através de uma atenção personalizada e de informações privilegiadas. Os nossos apaixonados condutores locais não são apenas guias, mas também contadores de histórias, prontos a revelar a rica história e o espírito vibrante do Porto. Quer esteja a passear pelo encantador bairro da Ribeira, a saborear vinhos do Porto de renome mundial em caves históricas, ou a contemplar o rio Douro a serpentear pela cidade, a Drive4You assegura que cada momento é repleto de autenticidade.\n\nPersonalizados de acordo com as suas preferências, os nossos passeios oferecem a flexibilidade para explorar o Porto ao seu ritmo. Desde maravilhas arquitectónicas como a estação de São Bento até às maravilhas contemporâneas da Casa da Música, a Drive4You cria um itinerário perfeito que capta a essência do passado e do presente do Porto.\n\nDeixe os mapas para trás e embarque numa viagem de descoberta com a Drive4You. Permita-nos navegar, educar e criar memórias que perdurarão por muito tempo após a conclusão da sua aventura no Porto. Descubra a alma do Porto como nunca antes, com a Drive4You como seu guia dedicado.",
      costs: {
        oneToThree: '500€',
        fourToSix: '650€',
        sevenPlus: '90€ per person'
      },
      duration: '12 horas',
      keyDetails: [
        "Vamos buscá-lo à sua morada designada.",
        "Hora de partida: 6:00"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [
        "Almoço"
      ],
      optional: [
        "Saltar a fila: pode escolher se quer comprar a excursão com bilhetes para as atracções incluídos ou não",
        "Os assentos para bebés estão disponíveis mediante pedido, se forem avisados no momento da reserva"
      ]
    },
    {
      slug: 'nazare-obidos-azenhas',
      id: 4,
      thumbnail: 'tours/thumb_nazare-obidos-azenhas@2x.webp',
      thumbnailAlt: 'Imagem - Nazaré/Óbidos/Azenhas do Mar',
      title: 'Nazaré/Óbidos/Azenhas do Mar',
      startingFrom: '60€',
      description: "Experimente as maravilhas de Portugal como nunca antes com a Drive4You, o seu melhor companheiro de viagem. Embarque numa viagem inesquecível enquanto o guiamos pelas cativantes localidades da Nazaré, Óbidos e Azenhas do Mar, cada uma delas repleta de história, charme e beleza natural.\n\nComeçando na Nazaré, famosa pelas suas ondas colossais e pelo seu rico património marítimo, a Drive4You garante que não perde um único ponto alto. Desde o icónico farol que oferece vistas panorâmicas da costa até à movimentada vila de pescadores, cada canto conta uma história. Seguindo para Óbidos, uma joia medieval rodeada por muralhas antigas, os nossos guias especializados conduzem-no pelas ruas de calçada, revelando tesouros escondidos como cafés encantadores e artesanato tradicional.\n\nÀ medida que o sol se põe, aventuramo-nos nas Azenhas do Mar, um paraíso costeiro onde o mar se encontra com falésias dramáticas. Com a Drive4You, poderá desfrutar de um pôr do sol de cortar a respiração, captando o momento para sempre. A nossa abordagem personalizada significa que é você que define o ritmo, dando-lhe tempo suficiente para explorar, tirar fotografias e mergulhar na cultura local. Com todos os detalhes meticulosamente planeados, desde o transporte confortável às informações privilegiadas, a Drive4You é o seu bilhete para uma aventura imersiva e inigualável pela Nazaré, Óbidos e Azenhas do Mar.\n\n",
      costs: {
        oneToThree: '350€',
        fourToSix: '400€',
        sevenPlus: '60€ per person'
      },
      duration: '8 horas',
      keyDetails: [
        "Vamos buscá-lo à sua morada designada.",
        "Hora de partida: 8:30"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [],
      optional: [
        "Os assentos para bebés estão disponíveis mediante pedido, se avisados no momento da reserva"
      ]
    },
    {
      slug: 'sesimbra-arrabida',
      id: 5,
      thumbnail: 'tours/thumb_sesimbra-arrabida@2x.webp',
      thumbnailAlt: 'Imagem - Sesimbra/Arrábida',
      title: 'Sesimbra/Arrábida',
      startingFrom: '50€',
      description: "Descubra a beleza inigualável de Sesimbra e da Arrábida com a Drive4You, a sua melhor companhia para uma experiência turística excecional. Mergulhe nas paisagens deslumbrantes e no rico património cultural destes destinos cativantes, enquanto desfruta do conforto e conveniência de um tour personalizado.\n\nA Drive4You distingue-se por oferecer uma mistura perfeita de experiência e hospitalidade. Os nossos experientes guias locais possuem um conhecimento profundo da história, da cultura e das jóias escondidas da região. Com a sua orientação, explorará as encantadoras ruas de Sesimbra, adornadas com a arquitetura tradicional portuguesa, e será cativado pelas águas azuis das suas praias imaculadas.\n\nA viagem continua até à Arrábida, onde as falésias escarpadas se encontram com as águas cristalinas do Oceano Atlântico. A Drive4You garante que não perderá uma única vista panorâmica ou um enclave sereno, levando-o aos pontos de vista mais inspiradores que se debruçam sobre esta maravilha natural.\n\nO que distingue a Drive4You é o seu empenho em criar uma excursão que se adapte às suas preferências. Quer seja um entusiasta da aventura, um aficionado da história ou um aficionado da fotografia, cada excursão é feita à medida dos seus desejos. Deleite-se com a liberdade de saborear cada momento, sabendo que a Drive4You concebeu um itinerário que vai ao encontro das suas paixões.\n\nscolha a Drive4You para uma viagem inesquecível por Sesimbra e Arrábida, onde cada curva da estrada traz novas descobertas e onde cada momento é marcado pelo conforto, conhecimento e pura maravilha.",
      costs: {
        oneToThree: '280€',
        fourToSix: '380€',
        sevenPlus: '50€ per person'
      },
      duration: '8 horas',
      keyDetails: [
        "Vamos buscá-lo à sua morada designada.",
        "Hora de partida: 9:00"
      ],
      included: [
        "Condutor/guia",
        "Recolha e entrega no hotel",
        "Excursão privada",
        "Água engarrafada",
        "Wi-Fi grátis"
      ],
      notIncluded: [],
      optional: [
        "Os assentos para bebés estão disponíveis mediante pedido, se avisados no momento da reserva"
      ]
    }
  ]
}

export const fleetData = [
  {
    id: 0,
    makeModel: 'Citroen Picasso',
    pax: 6,
    thumbnailAlt: 'Fleet - Citroen Picasso',
    thumbnailSrc: 'citroen-picasso.webp',
  },
  {
    id: 1,
    makeModel: 'Mercedes Classe E',
    pax: 4,
    thumbnailAlt: 'Fleet - Mercedes Classe E',
    thumbnailSrc: 'mercedes-classe-e.webp',
  }
]

export const transfersData = {
  en: [{
    slug: 'airport-lisbon',
    id: 0,
    thumbnail: 'tours/lisbon-by-night_v2.webp',
    thumbnailAlt: 'Image - Lisbon Airpot - Lisbon',
    title: 'Lisbon Airpot - Lisbon',
    startingFrom: '25€',
    description: 'Relax and have confidence in our ability to meet you at the airport and transport you to your hotel, completely free from stress or concerns. With Drive 4 You, one of our professional and amiable drivers will warmly welcome you at the airport. Utilize our transfer service to reach any destination in Lisbon and enjoy a comfortable and soothing journey.\n\nWe rely on skilled drivers to ensure an exceptional experience for you. Drive 4 You collaborates with numerous clients and possesses the necessary certifications and insurance in the transportation industry.\n\n- Guaranteed stress-free meet and greet upon arrival.\n- Effortlessly reach any location within the city.\n- Secure and cozy vehicles equipped with air conditioning.\n- Experienced and professional drivers.\n- Certified company.\n- We closely monitor flight delays and will wait for you if necessary.\n\nWith Drive 4 You, you can rest assured that our driver will be present to greet you upon arrival. As soon as you exit the gate at Lisbon Airport, our driver will be waiting near the Starbucks Coffee Shop in the Arrivals Hall. You can easily identify them as they will be holding a sign displaying "Drive 4 You." No need to worry if your flight is delayed, as we will continuously monitor and make necessary adjustments. Regardless of the circumstances, our driver will not leave the airport without you.\n\nPrior to your travel date, we will email you the contact details of your assigned driver, just in case. If you are departing, we will inquire about your pickup time.',
    duration: '1 hour'
  },
  {
    slug: 'airport-cascais',
    id: 1,
    thumbnail: 'cascais.webp',
    thumbnailAlt: 'Image - Lisbon Airpot - Cascais',
    title: 'Lisbon Airpot - Cascais',
    startingFrom: '40€',
    description: 'Relax and have confidence in our ability to meet you at the airport and transport you to your hotel, completely free from stress or concerns. With Drive 4 You, one of our professional and amiable drivers will warmly welcome you at the airport. Utilize our transfer service to reach any destination in Lisbon and enjoy a comfortable and soothing journey.\n\nWe rely on skilled drivers to ensure an exceptional experience for you. Drive 4 You collaborates with numerous clients and possesses the necessary certifications and insurance in the transportation industry.\n\n- Guaranteed stress-free meet and greet upon arrival.\n- Effortlessly reach any location within the city.\n- Secure and cozy vehicles equipped with air conditioning.\n- Experienced and professional drivers.\n- Certified company.\n- We closely monitor flight delays and will wait for you if necessary.\n\nWith Drive 4 You, you can rest assured that our driver will be present to greet you upon arrival. As soon as you exit the gate at Lisbon Airport, our driver will be waiting near the Starbucks Coffee Shop in the Arrivals Hall. You can easily identify them as they will be holding a sign displaying "Drive 4 You." No need to worry if your flight is delayed, as we will continuously monitor and make necessary adjustments. Regardless of the circumstances, our driver will not leave the airport without you.\n\nPrior to your travel date, we will email you the contact details of your assigned driver, just in case. If you are departing, we will inquire about your pickup time.',
    duration: '1 hour'
  },
  {
    slug: 'airport-sintra',
    id: 2,
    thumbnail: 'sintra@2x.webp',
    thumbnailAlt: 'Image - Lisbon Airpot - Sintra',
    title: 'Lisbon Airpot - Sintra',
    startingFrom: '45€',
    description: 'Relax and have confidence in our ability to meet you at the airport and transport you to your hotel, completely free from stress or concerns. With Drive 4 You, one of our professional and amiable drivers will warmly welcome you at the airport. Utilize our transfer service to reach any destination in Lisbon and enjoy a comfortable and soothing journey.\n\nWe rely on skilled drivers to ensure an exceptional experience for you. Drive 4 You collaborates with numerous clients and possesses the necessary certifications and insurance in the transportation industry.\n\n- Guaranteed stress-free meet and greet upon arrival.\n- Effortlessly reach any location within the city.\n- Secure and cozy vehicles equipped with air conditioning.\n- Experienced and professional drivers.\n- Certified company.\n- We closely monitor flight delays and will wait for you if necessary.\n\nWith Drive 4 You, you can rest assured that our driver will be present to greet you upon arrival. As soon as you exit the gate at Lisbon Airport, our driver will be waiting near the Starbucks Coffee Shop in the Arrivals Hall. You can easily identify them as they will be holding a sign displaying "Drive 4 You." No need to worry if your flight is delayed, as we will continuously monitor and make necessary adjustments. Regardless of the circumstances, our driver will not leave the airport without you.\n\nPrior to your travel date, we will email you the contact details of your assigned driver, just in case. If you are departing, we will inquire about your pickup time.',
    duration: '1 hour'
  }],
  pt: [{
    slug: 'airport-lisbon',
    id: 0,
    thumbnail: 'tours/lisbon-by-night_v2.webp',
    thumbnailAlt: 'Imagem - Aeroporto de Lisboa - Lisboa',
    title: 'Aeroporto de Lisboa - Lisboa',
    startingFrom: '25€',
    description: 'Descontraia e confie na nossa capacidade de o receber no aeroporto e de o transportar para o seu hotel, completamente livre de stress ou preocupações. Com a Drive 4 You, um dos nossos motoristas profissionais e amáveis irá recebê-lo calorosamente no aeroporto. Utilize o nosso serviço de transporte para chegar a qualquer destino em Lisboa e desfrute de uma viagem confortável e tranquila.\n\nContamos com condutores qualificados para assegurar uma experiência excecional para si. A Drive 4 You colabora com numerosos clientes e possui as certificações e seguros necessários na indústria de transportes.\n\n- Encontro e saudação garantidos e sem stress à chegada.\n- Chegar sem esforço a qualquer local dentro da cidade. \n- Veículos seguros e acolhedores equipados com ar condicionado.\n- Condutores experientes e profissionais.\n- Empresa certificada.\n- Monitorizamos de perto os atrasos dos voos e esperaremos por si se necessário.\n\nCom a Drive 4 You, pode ter a certeza que o nosso condutor estará presente para o receber à chegada. Assim que sair da porta de embarque no Aeroporto de Lisboa, o nosso motorista estará à espera perto do Starbucks Coffee Shop no Hall das Chegadas. Poderá identificá-lo facilmente, pois estará a segurar uma placa com a inscrição "Drive 4 You". Não precisa de se preocupar se o seu voo se atrasar, pois iremos monitorizar continuamente e fazer os ajustes necessários. Independentemente das circunstâncias, o nosso motorista não sairá do aeroporto sem si.\n\nAntes da data da sua viagem, enviar-lhe-emos um e-mail com os detalhes de contacto do seu motorista designado, por precaução. Se estiver de partida, iremos informá-lo sobre a sua hora de recolha.',
    duration: '1 hour'
  },
  {
    slug: 'airport-cascais',
    id: 1,
    thumbnail: 'cascais.webp',
    thumbnailAlt: 'Imagem - Aeroporto de Lisboa - Cascais',
    title: 'Aeroporto de Lisboa - Cascais',
    startingFrom: '40€',
    description: 'Descontraia e confie na nossa capacidade de o receber no aeroporto e de o transportar para o seu hotel, completamente livre de stress ou preocupações. Com a Drive 4 You, um dos nossos motoristas profissionais e amáveis irá recebê-lo calorosamente no aeroporto. Utilize o nosso serviço de transporte para chegar a qualquer destino em Cascais e desfrute de uma viagem confortável e tranquila.\n\nContamos com condutores qualificados para assegurar uma experiência excecional para si. A Drive 4 You colabora com numerosos clientes e possui as certificações e seguros necessários na indústria de transportes.\n\n- Encontro e saudação garantidos e sem stress à chegada.\n- Chegar sem esforço a qualquer local dentro da cidade. \n- Veículos seguros e acolhedores equipados com ar condicionado.\n- Condutores experientes e profissionais.\n- Empresa certificada.\n- Monitorizamos de perto os atrasos dos voos e esperaremos por si se necessário.\n\nCom a Drive 4 You, pode ter a certeza que o nosso condutor estará presente para o receber à chegada. Assim que sair da porta de embarque no Aeroporto de Lisboa, o nosso motorista estará à espera perto do Starbucks Coffee Shop no Hall das Chegadas. Poderá identificá-lo facilmente, pois estará a segurar uma placa com a inscrição "Drive 4 You". Não precisa de se preocupar se o seu voo se atrasar, pois iremos monitorizar continuamente e fazer os ajustes necessários. Independentemente das circunstâncias, o nosso motorista não sairá do aeroporto sem si.\n\nAntes da data da sua viagem, enviar-lhe-emos um e-mail com os detalhes de contacto do seu motorista designado, por precaução. Se estiver de partida, iremos informá-lo sobre a sua hora de recolha.',
    duration: '1 hour'
  },
  {
    slug: 'airport-sintra',
    id: 2,
    thumbnail: 'sintra@2x.webp',
    thumbnailAlt: 'Imagem - Aeroporto de Lisboa - Sintra',
    title: 'Aeroporto de Lisboa - Sintra',
    startingFrom: '45€',
    description: 'Descontraia e confie na nossa capacidade de o receber no aeroporto e de o transportar para o seu hotel, completamente livre de stress ou preocupações. Com a Drive 4 You, um dos nossos motoristas profissionais e amáveis irá recebê-lo calorosamente no aeroporto. Utilize o nosso serviço de transporte para chegar a qualquer destino em Sintra e desfrute de uma viagem confortável e tranquila.\n\nContamos com condutores qualificados para assegurar uma experiência excecional para si. A Drive 4 You colabora com numerosos clientes e possui as certificações e seguros necessários na indústria de transportes.\n\n- Encontro e saudação garantidos e sem stress à chegada.\n- Chegar sem esforço a qualquer local dentro da cidade. \n- Veículos seguros e acolhedores equipados com ar condicionado.\n- Condutores experientes e profissionais.\n- Empresa certificada.\n- Monitorizamos de perto os atrasos dos voos e esperaremos por si se necessário.\n\nCom a Drive 4 You, pode ter a certeza que o nosso condutor estará presente para o receber à chegada. Assim que sair da porta de embarque no Aeroporto de Lisboa, o nosso motorista estará à espera perto do Starbucks Coffee Shop no Hall das Chegadas. Poderá identificá-lo facilmente, pois estará a segurar uma placa com a inscrição "Drive 4 You". Não precisa de se preocupar se o seu voo se atrasar, pois iremos monitorizar continuamente e fazer os ajustes necessários. Independentemente das circunstâncias, o nosso motorista não sairá do aeroporto sem si.\n\nAntes da data da sua viagem, enviar-lhe-emos um e-mail com os detalhes de contacto do seu motorista designado, por precaução. Se estiver de partida, iremos informá-lo sobre a sua hora de recolha.',
    duration: '1 hour'
  }]
}
